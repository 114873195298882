import { Pipe, PipeTransform } from "@angular/core";
import { DecoStoreService } from "@app/stores/deco.store";
import { map, Observable } from "rxjs";

@Pipe({
    name: "qualificationName",
    standalone: true,
})
export class QualificationNamePipe implements PipeTransform {
    constructor(private decoStoreService: DecoStoreService) {}

    transform(value: any): Observable<string> {
        return this.decoStoreService
            .getQualifications()
            .pipe(map((res) => res.risultati.find((x) => x.uid === value)?.nome || ""));
    }
}

@Pipe({
    name: "qualificationDesc",
    standalone: true,
})
export class QualificationDescPipe implements PipeTransform {
    constructor(private decoStoreService: DecoStoreService) {}

    transform(value: any): Observable<string> {
        return this.decoStoreService
            .getQualifications()
            .pipe(map((res) => res.risultati.find((x) => x.uid === value)?.descrizione || ""));
    }
}
