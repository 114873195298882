import { ChangeDetectionStrategy, Component, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import {
    AutocompleteItem,
    ItButtonDirective,
    ItCheckboxComponent,
    ItChipComponent,
    ItCollapseComponent,
    ItInputComponent,
    ItListComponent,
    ItListItemComponent,
    ItModalComponent,
    ItNotificationService,
    ItSelectComponent,
    ItSidebarComponent,
    ItTooltipDirective,
    ItValidators,
    SelectControlOption,
} from "design-angular-kit";
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from "@angular/router";
import { UserService } from "@app/services/user.service";
import { AdditionalInfo, User } from "@app/models/user";
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { Qualification, QualificationType, Role, RoleInstitution, RoleType } from "@app/models/role";
import { TooltipPlacement } from "@app/app.constants";
import { RoleService } from "@app/services/role.service";
import { DecoStoreService } from "@app/stores/deco.store";
import { RoleNamePipe } from "@app/pipes/role.pipe";
import { InstitutionNamePipe } from "@app/pipes/institution.pipe";
import { QualificationDescPipe, QualificationNamePipe } from "@app/pipes/qualification.pipe";
import { QualificationListComponent } from "../qualification-list/quolification-list.component";
import { AsyncPipe, Location } from "@angular/common";
import { UppercaseDirective } from "@app/directives/uppercase.directive";
import { RoutingService } from "@app/services/routing.service";
import { AuthService } from "@app/services/auth.service";
import { PageType } from "@app/models/page";
import { BreadcrumbService } from "@app/services/breadcrumb.service";
import { DecoService } from "@app/services/deco.service";
import { ItAutocompleteComponent } from "@app/components/custom/autocomplete/autocomplete.component";
import { Subject, switchMap, takeUntil, tap } from "rxjs";

@Component({
    selector: "app-user-detail",
    standalone: true,
    templateUrl: "./user-detail.component.html",
    imports: [
        TranslateModule,
        ItButtonDirective,
        ItInputComponent,
        ItCheckboxComponent,
        ReactiveFormsModule,
        ItSidebarComponent,
        ItListComponent,
        ItListItemComponent,
        ItSelectComponent,
        ItTooltipDirective,
        ItChipComponent,
        ItModalComponent,
        RouterLink,
        RouterLinkActive,
        FormsModule,
        RoleNamePipe,
        InstitutionNamePipe,
        QualificationNamePipe,
        QualificationDescPipe,
        QualificationListComponent,
        AsyncPipe,
        UppercaseDirective,
        ItCollapseComponent,
        ItAutocompleteComponent,
    ],
})
export class UserDetailsComponent implements OnInit {
    @ViewChild("roleModal") roleModal!: ItModalComponent;
    user = new User({ entiRuoli: [] });
    utentiInfo = new AdditionalInfo();
    userId = this.route.snapshot.paramMap.get("id");
    userForm = new FormGroup({
        uid: new UntypedFormControl(),
        cf: new UntypedFormControl("", [Validators.required, ItValidators.taxCode]),
        cognome: new UntypedFormControl({ value: "", disabled: true }, Validators.required),
        nome: new UntypedFormControl({ value: "", disabled: true }, [Validators.required]),
    });
    roleForm = new FormGroup({
        uid: new UntypedFormControl(),
        dipendenteEnte: new UntypedFormControl(),
        disabilitato: new UntypedFormControl(),
        ente: new UntypedFormControl(null, Validators.required),
        ruolo: new UntypedFormControl(null, Validators.required),
        telefono: new UntypedFormControl("", ItValidators.tel),
        mail: new UntypedFormControl("", ItValidators.email),
        utentiAbilitazioni: new UntypedFormControl([]),
    });
    infoForm = new FormGroup({
        uid: new UntypedFormControl(),
        indirizzo: new UntypedFormControl(),
        comune: new UntypedFormControl(),
        albo: new UntypedFormControl(),
        provinciaAlbo: new UntypedFormControl(),
        numeroIscrizione: new UntypedFormControl(),
        telefono: new UntypedFormControl("", ItValidators.tel),
        mail: new UntypedFormControl("", ItValidators.email),
        abilitazioneProfessionale: new UntypedFormControl(),
        abilitazioneProfessionaleData: new UntypedFormControl(),
    });
    roles: RoleInstitution[] = [];
    activeFragment: string | null = null;
    collapsedSidebar = false;
    municipalities: SelectControlOption<number>[] = [];
    provices: SelectControlOption<number>[] = [];
    rolesDeco: SelectControlOption<number>[] = [];
    institutionsDeco: SelectControlOption<number>[] = [];
    readonly TooltipPlacement = TooltipPlacement;
    roleQualifications: Qualification[] = [];
    selectedRole: RoleInstitution = new RoleInstitution();
    currentRoute: string | null = null;
    pageType: string | null = null;
    QualificationType = QualificationType;
    isSaving = false;
    selectedMunicipality: number | null = null;
    destroySubject: Subject<void> = new Subject();

    constructor(
        private readonly notificationService: ItNotificationService,
        private decoStoreService: DecoStoreService,
        private roleService: RoleService,
        private router: Router,
        private route: ActivatedRoute,
        private routingService: RoutingService,
        protected authService: AuthService,
        private breadcrumbService: BreadcrumbService,
        private location: Location,
        readonly decoService: DecoService,
        readonly userService: UserService
    ) {}

    get username() {
        return this.isNew
            ? this.userForm.value.nome || this.userForm.value.cognome
                ? `${this.userForm.value.nome} ${this.userForm.value.cognome}`
                : ""
            : this.user.nome && this.user.cognome
            ? `${this.user.nome} ${this.user.cognome}`
            : "";
    }

    get isNew() {
        return !this.userId;
    }

    get isTechnician() {
        return !!this.roles.find((x) => x.ruolo === RoleType.Technician);
    }

    get isFormValid() {
        return this.userForm.valid && this.infoForm.valid;
    }

    get isProfileView() {
        return this.pageType === PageType.PROFILE;
    }

    get isEditView() {
        return !this.isNew && !this.isProfileView;
    }

    get sidebarLink() {
        return !this.isNew
            ? this.isProfileView
                ? ["/profilo", this.userId]
                : ["/utenti", "utente", this.userId]
            : ["/utenti", "utente", "crea"];
    }

    get hasUnsavedChanges() {
        return (
            (this.userForm.dirty ||
                this.infoForm.dirty ||
                (this.isNew && (this.roles.length || this.roleForm.dirty))) &&
            !this.isSaving
        );
    }

    ngOnInit() {
        this.routingService.currentRoute$.pipe(takeUntil(this.destroySubject)).subscribe((route) => {
            this.currentRoute = route;
        });
        this.routingService.pageType$.pipe(takeUntil(this.destroySubject)).subscribe((pageType) => {
            this.pageType = pageType;
            if (this.isProfileView) {
                this.userForm.get("cf")?.disable();
                this.userForm.get("nome")?.enable();
                this.userForm.get("cognome")?.enable();
                this.roleForm.get("dipendenteEnte")?.disable();
                this.roleForm.get("disabilitato")?.disable();
                this.roleForm.get("ente")?.disable();
                this.roleForm.get("ruolo")?.disable();
            } else if (this.isEditView) {
                this.userForm.disable();
                this.infoForm.disable();
            }
        });
        this.route.fragment
            .pipe(takeUntil(this.destroySubject))
            .subscribe((fragment) => (this.activeFragment = fragment));
        this.decoStoreService
            .getMunicipalities()
            .pipe(takeUntil(this.destroySubject))
            .subscribe({ next: (res) => (this.municipalities = res) });
        this.decoStoreService
            .getProvinces()
            .pipe(takeUntil(this.destroySubject))
            .subscribe({ next: (res) => (this.provices = res) });

        this.authService
            .getUser()
            .pipe(
                switchMap((loggedUser) =>
                    this.roleService.get(loggedUser.utenteEnteRuolo.ruolo).pipe(
                        switchMap((role) =>
                            this.decoStoreService.getInstitutions().pipe(
                                tap((res) => {
                                    this.institutionsDeco = role.enteFiltro
                                        ? res.filter((x) => x.value === loggedUser.utenteEnteRuolo.ente)
                                        : res;
                                })
                            )
                        )
                    )
                )
            )
            .pipe(takeUntil(this.destroySubject))
            .subscribe();

        this.roleService
            .getAll()
            .pipe(takeUntil(this.destroySubject))
            .subscribe({
                next: (res) => (this.rolesDeco = res.risultati.map((x: Role) => ({ value: x.uid, text: x.nome }))),
            });

        if (!this.isNew && this.userId) {
            const userServiceCall = this.isProfileView
                ? this.userService.getProfile()
                : this.userService.get(this.userId);

            userServiceCall.pipe(takeUntil(this.destroySubject)).subscribe({
                next: (res) => {
                    this.user = res;
                    this.getUserRoles();
                    this.utentiInfo = this.user.utentiInfo;
                    this.utentiInfo.abilitazioneProfessionaleData =
                        this.utentiInfo.abilitazioneProfessionaleData?.split("T")[0];
                    this.userForm.patchValue(this.user);
                    this.infoForm.patchValue(this.utentiInfo);
                    this.breadcrumbService.setBreadcrumb(this.username);
                    this.utentiInfo.comune &&
                        this.decoService
                            .getMunicipality(this.utentiInfo.comune)
                            .pipe(takeUntil(this.destroySubject))
                            .subscribe({
                                next: (res) => {
                                    this.selectedMunicipality = res.uid;
                                    this.infoForm.patchValue({ comune: res.nome });
                                },
                            });
                },
            });
        }
    }

    getUserRoles() {
        if (this.userId) {
            const userServiceCall = this.isProfileView
                ? this.userService.getProfileRoles()
                : this.userService.getUserRoles(this.userId);

            userServiceCall.pipe(takeUntil(this.destroySubject)).subscribe({
                next: (res) => {
                    this.roles = res.risultati;
                },
            });
        }
    }

    onSubmit() {
        this.userForm.markAllAsTouched();
        this.isTechnician && this.infoForm.markAllAsTouched();
        if (!this.isFormValid) return;
        this.isSaving = true;
        this.user = this.userForm.getRawValue() as User;
        this.infoForm.patchValue({ comune: this.selectedMunicipality });
        this.user.utentiInfo = this.infoForm.getRawValue();
        if (this.isNew) this.user.entiRuoli = this.roles;
        const userServiceCall = this.isNew
            ? this.userService.create(this.user)
            : this.userService.updateProfile(this.user);

        userServiceCall.pipe(takeUntil(this.destroySubject)).subscribe({
            next: (res) => {
                this.notificationService.success(res.titolo, res.messaggio);
                this.isProfileView &&
                    this.authService
                        .reloadUser()
                        .pipe(takeUntil(this.destroySubject))
                        .subscribe({
                            next: () => {
                                this.authService.getUser(true).pipe(takeUntil(this.destroySubject)).subscribe();
                            },
                        });
                this.goToList();
            },
            error: () => (this.isSaving = false),
        });
    }

    goToList() {
        this.isProfileView ? this.router.navigate(["/"]) : this.router.navigate(["utenti/lista"]);
    }

    toggleSidebar() {
        this.collapsedSidebar = !this.collapsedSidebar;
    }

    addRole() {
        this.selectedRole = new RoleInstitution({ utentiAbilitazioni: [] });
        this.roleQualifications = [];
        this.roleForm.reset({ utentiAbilitazioni: [] });
        this.roleModal.show();
    }

    editRole(id: number) {
        if (!this.isProfileView) {
            this.userService
                .getUserRole(id)
                .pipe(takeUntil(this.destroySubject))
                .subscribe({
                    next: (res) => {
                        this.selectedRole = res;
                        this.roleForm.patchValue(res);
                        this.selectedRole.ruolo && this.onRoleChange();
                        this.roleModal.show();
                    },
                });
        } else {
            var roleProfile = this.roles.find((x) => x.uid === id);
            if (roleProfile) {
                this.selectedRole = roleProfile;
                this.roleForm.patchValue(roleProfile);
                this.selectedRole.ruolo && this.onRoleChange();
                this.roleModal.show();
            }
        }
    }

    saveRole() {
        this.roleForm.markAllAsTouched();
        if (this.roleForm.invalid) return;
        if (!this.isProfileView) {
            const { value: formQualifValues } = this.roleForm.controls.utentiAbilitazioni;
            const updatedQualifications = [
                ...formQualifValues.filter((item: Qualification) =>
                    this.selectedRole.utentiAbilitazioni.some((change) => change.abilitazione === item.abilitazione)
                ),
                ...this.selectedRole.utentiAbilitazioni.filter((change) =>
                    formQualifValues.every((item: Qualification) => item.abilitazione !== change.abilitazione)
                ),
            ];
            this.roleForm.patchValue({ utentiAbilitazioni: updatedQualifications });
        }

        if (this.isNew) {
            const id = this.roles.findIndex((x) => x.uid === this.roleForm.value.uid);
            id >= 0 ? (this.roles[id] = this.roleForm.getRawValue()) : this.roles.push(this.roleForm.getRawValue());
            this.roleModal.hide();
            return;
        }

        if (this.userId) {
            const userServiceCall = this.isProfileView
                ? this.userService.updateProfileContact(this.roleForm.getRawValue())
                : this.roleForm.value.uid
                ? this.userService.updateUserRole(this.roleForm.getRawValue())
                : this.userService.createUserRole(this.userId, this.roleForm.getRawValue());

            userServiceCall.pipe(takeUntil(this.destroySubject)).subscribe({
                next: (res) => {
                    this.notificationService.success(res.titolo, res.messaggio);
                    this.getUserRoles();
                },
                error: () => (this.isSaving = false),
            });

            this.roleModal.hide();
        }
    }

    deleteRole(id: number) {
        if (this.isNew) this.roles = this.roles.filter((x) => x.uid !== id);
        else {
            this.userService
                .deleteUserRole(id)
                .pipe(takeUntil(this.destroySubject))
                .subscribe({
                    next: (res) => {
                        this.notificationService.success(res.titolo, res.messaggio);
                        this.getUserRoles();
                    },
                });
        }
    }

    onRoleChange(update: boolean = false) {
        this.roleForm.value.ruolo &&
            this.roleService
                .get(this.roleForm.value.ruolo)
                .pipe(takeUntil(this.destroySubject))
                .subscribe({
                    next: (res) => {
                        this.roleQualifications = res.abilitazioniRuoli;
                        if (update) this.selectedRole.utentiAbilitazioni = res.abilitazioniRuoli;
                    },
                });
    }

    onMunicipalitySelected(item: AutocompleteItem): void {
        this.selectedMunicipality = item.additionalData;
    }

    onUserSelected(item: AutocompleteItem): void {
        item.additionalData ? this.userForm.patchValue(item.additionalData) : this.userForm.reset();
    }

    ngOnDestroy() {
        this.breadcrumbService.setBreadcrumb(null);
        this.destroySubject.next();
    }
}
